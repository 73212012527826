import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";
import locales from "../../../constants";

const Twierdza = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #A08C55, #AE985B, #BBA360, #AE985B, #A08C55 )",
        icons: "#c5af71",
        navClass: "twierdza",
      }}
      seo={{
        title: "Twierdza Krzyżowiec II",
        headerTitle: "twierdza",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/twierdza_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/twierdza-krzyzowiec/",
      }}
    >
      <PortfolioHeader className="tyta" name="twierdza" height="720" />
      <section className="container-fluid twierdza_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Stronghold: Crusader II</h1>
              <ul>
                <li>Website</li>
                <li className="first">Product campaign</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                We created a website for the premiere of the game Stronghold
                Crusader II for ABC Data. We were also given the task of
                promoting the game on social media and via the Google Ads.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="twierdza_section_3">
        <div className="laptop-wrapper">
          <img
            className="img-fluid castle"
            src={require("../../../assets/img/portfolio/twierdza_keeps.png")}
            alt=""
          />
          <img
            className="img-fluid crusader1"
            src={require("../../../assets/img/portfolio/twierdza_crusader2.png")}
            alt=""
          />
          <img
            className="img-fluid crusader2"
            src={require("../../../assets/img/portfolio/twierdza_crusader1.png")}
            alt=""
          />
          <div className="scroll_wrapper">
            <div className="img_wrapper">
              <img
                className="screen"
                src={require("../../../assets/img/portfolio/twierdza_page1.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Twierdza;
